import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Button from "../components/button"
import InfiniteLoop from "../components/infinite-loop"

const HowDoesItWorkPage = () => {
  return (
    <Layout>
      <Seo title="Life-tuning | What is Life-tuning and what you can expect!" />
      <StaticImage
        style={{
          width: "100%",
          boxShadow: "0 15px 16px 2px rgba(0, 0, 0, 0.1)",
        }}
        src="../images/create-a-plan-life-tuning.jpeg"
        height={500}
        quality={100}
        objectFit={"cover"}
        objectPosition={"50% 50%"}
        formats={["auto", "webp", "avif"]}
        alt="what is life-tuning and what you can expect"
        placeholder="blurred"
      />
      <InfiniteLoop loop={4}>
        In the end, we only regret the chances we didn’t take
      </InfiniteLoop>
      <section>
        <div className="text-wrapper">
          <h2>How does it work?</h2>
          <p>You only need a minute to get started:</p>
          <ul>
            <li>
              Book your{" "}
              <Link to="/contact" className="link">
                free discovery call
              </Link>
              .
            </li>
            <li>
              I will be in touch within 48 hours to arrange a zoom call with
              you.
            </li>
            <li>
              During this call, we'll talk about you, your vision and your
              goals. We'll discuss which areas in your life you'd like to
              improve and what might be holding you back.
            </li>
            <li>
              No strings attached, the purpose of this call is to get to know
              each other and figure out if I'm the right coach for you.
            </li>
            <li>
              The discovery call usually lasts between an hour/hour and a half.
            </li>
            <li>
              You can be anywhere in the world and still book a call, all you
              need is a phone or a laptop with an internet connection and we're
              good to go!
            </li>
          </ul>
          <p>
            Remember, there is no such thing as the “perfect moment”. Change
            will only happen when you decide to take action. So, ask yourself:
            "If not now, when?"
          </p>
          <p>Just click on the link below and let’s talk!</p>
          <Button mt={true} to="/contact">
            Book a free discovery call
          </Button>
        </div>
      </section>
      <section className="section-last">
        <div className="text-wrapper ">
          <h2>The difference between life coaching and therapy</h2>
          <p>
            In both life coaching and therapy, the sessions are designed to
            bring you deep insights, personal growth and a feeling of
            self-accomplishment. The big difference between the two though is in
            how this will be done.
          </p>
          <p>
            In psychology and psychoanalysis, the main focus tends to be looking
            into your past in order to uncover where certain problematic
            behaviors or feelings come from. The goal here is to unravel past
            traumas and understand them in order to diagnose and heal them. This
            also applies to self-sabotaging behaviors or working through
            difficult feelings, it’s more about understanding them first in
            order to change or navigate them.
          </p>
          <p>
            In life coaching, the main focus is more on the present and looking
            towards the future in order to create clear goals and objectives to
            work towards. This includes an action plan to identify and overcome
            obstacles or self sabotaging behaviors, without diagnosing or
            unraveling them. Of course there’s room to talk about the past as
            this is part of your story and who you are as a person, but there’s
            less introspection and analysis involved.
          </p>
          <p>
            As a life coach I am qualified to guide you through your own journey
            and process, but not to diagnose or treat any mental health
            conditions. This however doesn’t mean that these will not be taken
            into account. Your overall well being is part of the work and
            necessary in order to reach your goals. It simply means that if you
            feel the need for therapy, I will do my best to refer you to a
            mental health professional to make sure that you get all the help
            you need.
          </p>
          <p>
            Mental health is an essential part of life for everyone, so feel
            free to reach out at any time.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default HowDoesItWorkPage
